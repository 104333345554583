import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const AboutModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="About" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-sm text-gray-500"><strong>Passwordle</strong> was created by Ashley Knowles (<a href="https://twitter.com/ashkbne">@ashkbne</a>)</p>
      <p className="text-sm text-gray-500">
        Passwordle uses Hannah Park's open source clone of the game Wordle - {' '}
        <a
          href="https://github.com/hannahcode/wordle"
          className="underline font-bold"
        >
          check out the code here
        </a>{' '}
        and{' '}
        <a
          href="https://www.powerlanguage.co.uk/wordle/"
          className="underline font-bold"
        >
          play the original Wordle here
        </a>
      </p>
      
    </BaseModal>
  )
}
